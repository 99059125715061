import {TableRow, TableCell, TableBody as MUITableBody} from '@mui/material';

import {toUpperCaseFirstChar} from 'environment/utils/toUpperCaseFirstChar';
import {splitStringFromCamelCase} from 'environment/utils/splitStringFromCamelCase';

interface TableBodyVerticalProps<T> {
  rows: Partial<T>;
}

const TableVerticalBody = <T,>({rows}: TableBodyVerticalProps<T>) => {
  return (
    <MUITableBody>
      {Object.entries(rows).map(([key, value]) => (
        <TableRow key={key}>
          <TableCell sx={{fontWeight: 'bold'}}>
            {toUpperCaseFirstChar(splitStringFromCamelCase(key))}
          </TableCell>
          <TableCell>
            {value === '' || value === null ? '-' : (value as string | number)}
          </TableCell>
        </TableRow>
      ))}
    </MUITableBody>
  );
};

export default TableVerticalBody;
