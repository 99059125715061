import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control, useWatch} from 'react-hook-form';
import {useCallback} from 'react';

import {Button, DatePicker, Input, InputLocation, Selector} from 'atoms';
import {JobShiftType, JobType} from 'environment/types/createJob';
import JobShfts from './JobShfts';

interface IProps {
  control: Control<any>;
  loading: boolean;
  setValueHandler: (name: any, value: string | number) => void;
  onSubmit: () => void;
}

const CreateJobForm = ({
  control,
  loading,
  onSubmit,
  setValueHandler,
}: IProps) => {
  const {t} = useTranslation();

  const jobValue = useWatch({
    control,
    name: 'job',
  });

  const jobTypeItems = [
    {value: JobType.Production, label: t('Production')},
    {value: JobType.Service, label: t('Service')},
    {value: JobType.Maintenance, label: t('Maintenance')},
  ];
  const jobShiftItems = [
    {value: JobShiftType.Fulltime, label: t('Full.time')},
    {value: JobShiftType.Parttime, label: t('Part.time')},
  ];

  const onLocationSelect = useCallback(
    (locationObj: any) => {
      if (locationObj?.street_number) {
        setValueHandler('location.number', locationObj.street_number);
      }
      if (locationObj?.route) {
        setValueHandler('location.street', locationObj.route);
      }
      if (locationObj?.locality) {
        setValueHandler('location.city', locationObj.locality);
      }
      if (locationObj?.administrative_area_level_1) {
        setValueHandler(
          'location.state',
          locationObj.administrative_area_level_1,
        );
      }
      if (locationObj?.postal_code) {
        setValueHandler('location.zipcode', locationObj.postal_code);
      }
    },
    [setValueHandler],
  );

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      autoComplete="off"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '400px',
        }}>
        <Input
          control={control}
          name="job.companyName"
          label={t('Restaurant.chain')}
        />
        <Input
          control={control}
          name="job.contactInfo"
          label={t('Contact.info')}
        />
        <Input control={control} name="job.title" label={t('Job.title')} />
        <Input
          control={control}
          name="job.description"
          label={t('Job.description')}
        />
        <Selector
          control={control}
          menuItems={jobTypeItems}
          name={'job.jobType'}
          label={t('Job.type')}
        />
        <Selector
          control={control}
          menuItems={jobShiftItems}
          name={'job.type'}
          label={t('Shift.type')}
        />
        <Input
          control={control}
          name="job.phoneNumber"
          label={t('Phone.number')}
        />
        <Input
          control={control}
          name="job.minExperience"
          label={t('Minimal.experience')}
        />
        <Input
          control={control}
          name="job.payRate"
          label={t('Rate.per.hour.($)')}
        />
        <Input
          control={control}
          name="job.hoursPerWeek"
          label={t('Hours.per.week')}
        />
        <DatePicker
          name="job.startDate"
          control={control}
          label={t('Start.date')}
          minDate={new Date()}
          maxDate={jobValue.endDate}
        />
        <DatePicker
          name="job.endDate"
          control={control}
          label={t('End.date')}
          minDate={jobValue.startDate ? jobValue.startDate : new Date()}
        />
        <Typography textAlign={'center'} mb={3} variant="h4">
          {t('Add.Job.Address')}
        </Typography>
        <InputLocation
          onLocationSelect={onLocationSelect}
          label={t('Address')}
        />
        <Input control={control} name="location.state" label={t('State')} />
        <Input control={control} name="location.city" label={t('City')} />
        <Input control={control} name="location.street" label={t('Street')} />
        <Input control={control} name="location.number" label={t('Number')} />
        <Input control={control} name="location.zipcode" label={t('ZIP')} />
      </Box>
      <Typography textAlign={'center'} mb={3} variant="h4">
        {t('Job.Shifts')}
      </Typography>
      <JobShfts control={control} setValueHandler={setValueHandler} />

      <Button
        sx={{width: '400px'}}
        loading={loading}
        disabled={loading}
        type="submit">
        {t('Save')}
      </Button>
    </Box>
  );
};

export default CreateJobForm;
