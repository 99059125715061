import {Link, Breadcrumbs as MUIBreadcrumbs, Typography} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import {toUpperCaseFirstChar} from 'environment/utils/toUpperCaseFirstChar';

const Breadcrumbs = () => {
  const location = useLocation();

  const pathnames = location.pathname.split('/').filter(x => x);

  const replaceDashOnSpace = (str: string) => {
    return str.replace(/-/g, ' ');
  };

  const filterBreadcrumbs = (route: string) => {
    const showRoute = route.split('_').at(0) || '';
    return replaceDashOnSpace(toUpperCaseFirstChar(showRoute));
  };

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <HomeIcon sx={{mt: '4px'}} fontSize="small" />
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {filterBreadcrumbs(value)}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={to}
            key={to}>
            {filterBreadcrumbs(value)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
