import axiosService from '../axios';
import {employerUrl} from '../url';

export const getEmployerRequest = () => {
  return axiosService.get(employerUrl({}).getEmployer);
};

export const editEmployerRequest = (id: string, body: any) => {
  return axiosService.patch(employerUrl({id}).employer, body);
};

export const deleteEmployerRequest = (id: string) => {
  return axiosService.delete(employerUrl({id}).employer);
};
