import {TableContainer as MUITableContainer, Paper, Table} from '@mui/material';

interface TableContainerProps {
  minWidth?: number;
  children: React.ReactNode;
}

const TableContainer = ({minWidth = 650, children}: TableContainerProps) => {
  return (
    <MUITableContainer component={Paper}>
      <Table sx={{minWidth}} aria-label="simple table">
        {children}
      </Table>
    </MUITableContainer>
  );
};

export default TableContainer;
