import {Box, Typography} from '@mui/material';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, Input} from 'atoms';
import {Link} from 'react-router-dom';
import {routes} from 'environment/constants/routes';
import {SignUpValues} from 'environment/types/signUp';

const boxShadow = '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';

interface IProps {
  control: Control<SignUpValues>;
  loading: boolean;
  onSubmit: () => void;
}

const SignUpTemplate = ({control, loading, onSubmit}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '500px',
          padding: '50px 20px',
          boxShadow,
          borderRadius: '10px',
        }}>
        <Box mb={3} component="form" onSubmit={onSubmit} autoComplete="off">
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              mb: '20px',
            }}>
            {t('Sign.up')}
          </Typography>
          <Input
            control={control}
            name={'email'}
            label={t('Email')}
            fullWidth
            sx={{mb: 2}}
          />
          <Input
            control={control}
            name={'password'}
            label={t('Password')}
            showPassword
            fullWidth
            sx={{mb: 2}}
          />
          <Input
            control={control}
            name={'passwordConfirm'}
            label={t('Confirm.Password')}
            showPassword
            fullWidth
            sx={{mb: 2}}
          />
          <Input
            control={control}
            name={'name'}
            label={t('Full.Name')}
            fullWidth
            sx={{mb: 2}}
          />
          <Input
            control={control}
            name={'companyName'}
            label={t('Restaurant.chain')}
            fullWidth
            sx={{mb: 2}}
          />
          <Button fullWidth loading={loading} disabled={loading} type="submit">
            {t('Sign.up')}
          </Button>
        </Box>
        <Typography component="span">{t('I.have.account')}</Typography>
        <Box component={Link} ml={1} to={routes.auth.logIn}>
          {t('Log.in')}
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpTemplate;
