import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useState} from 'react';

import {Dispatch, RootState} from 'environment/store';
import {RECOVERY_SCHEMA} from 'environment/validation';
import {RecoveryTemplate} from 'templates';
import {RecoveryValues} from 'environment/types/changePassword';

const Recovery = () => {
  const dispatch = useDispatch<Dispatch>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.auth,
  );

  const {handleSubmit, control} = useForm<RecoveryValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: RECOVERY_SCHEMA,
  });

  const onSubmit = (value: RecoveryValues) => {
    dispatch.auth.recovery({
      data: value,
      callback: () => setIsSuccess(true),
    });
  };

  return (
    <RecoveryTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      isSuccess={isSuccess}
    />
  );
};

export default Recovery;
