import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {Control, useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment, {Moment} from 'moment';

import {DatePicker, TimePicker} from 'atoms';
import {Schedules} from 'environment/types/createJob';

interface FormValues {
  schedules: Schedules[];
}
interface IProps {
  control: Control<any>;
  setValueHandler: (name: any, value: string | number) => void;
}

const JobShfts = ({control, setValueHandler}: IProps) => {
  const {t} = useTranslation();

  const schedulesValue = useWatch({
    control,
    name: 'schedules',
  });
  const jobValue = useWatch({
    control,
    name: 'job',
  });

  const {fields, append, remove} = useFieldArray<
    FormValues,
    'schedules',
    'schedulesId'
  >({
    control,
    name: 'schedules',
    keyName: 'schedulesId',
  });

  const onChangeDayOfWeek = (index: number) => {
    return (value: Moment) => {
      setValueHandler(`schedules.${index}.dayOfWeek`, value.day());
    };
  };

  return (
    <TableContainer
      sx={{
        mb: 4,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('DAY')}</TableCell>
            <TableCell align="center">{t('START')}</TableCell>
            <TableCell align="center">{t('END')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item, index) => (
            <TableRow
              key={item.schedulesId}
              sx={{verticalAlign: 'top', '& td': {pb: 0}}}>
              <TableCell>
                {
                  <DatePicker
                    name={`schedules.${index}.date`}
                    control={control}
                    label={t('Date')}
                    handleChange={onChangeDayOfWeek(index)}
                    minDate={
                      jobValue.startDate ? jobValue.startDate : new Date()
                    }
                    maxDate={jobValue.endDate}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <TimePicker
                    name={`schedules.${index}.startTime`}
                    control={control}
                    label={t('Start.time')}
                    minutesStep={5}
                    maxTime={moment(schedulesValue[index]?.endTime)}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <TimePicker
                    control={control}
                    name={`schedules.${index}.endTime`}
                    label={t('End.time')}
                    minutesStep={5}
                    minTime={moment(schedulesValue[index]?.startTime)}
                  />
                }
              </TableCell>
              <TableCell>
                {index !== 0 && (
                  <IconButton size="medium" onClick={() => remove(index)}>
                    <DeleteIcon color="error" fontSize="large" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <IconButton
        onClick={() =>
          append({
            dayOfWeek: 0,
            date: '',
            startTime: '',
            endTime: '',
          })
        }
        size="large">
        <AddCircleOutlineIcon color="success" fontSize="large" />
      </IconButton>
    </TableContainer>
  );
};

export default JobShfts;
