import {Route, Routes} from 'react-router-dom';

import {
  NotFound,
  LogIn,
  Dashboard,
  SignUp,
  VerificationSignUp,
  Recovery,
  ChangePassword,
  CreateJob,
  AllMyJobs,
  JobDetails,
  SeekerDetails,
  Profile,
} from 'pages';
import {routes} from 'environment/constants/routes';

const AppRouter = () => {
  return (
    <Routes>
      <Route path={routes.auth.recovery} element={<Recovery />}></Route>
      <Route
        path={routes.auth.recoveryByToken}
        element={<ChangePassword />}></Route>
      <Route
        path={routes.auth.signUpSuccess}
        element={<VerificationSignUp />}></Route>
      <Route path={routes.auth.logIn} element={<LogIn />}></Route>
      <Route path={routes.auth.singUp} element={<SignUp />}></Route>
      <Route path={routes.dashboard} element={<Dashboard />}>
        <Route path={routes.jobs.createJob} element={<CreateJob />} />
        <Route path={routes.jobs.list} element={<AllMyJobs />} />
        <Route path={routes.jobs.listDetailsId} element={<JobDetails />} />
        <Route path={routes.jobs.applicantId} element={<SeekerDetails />} />
        <Route path={routes.profile} element={<Profile />} />
      </Route>
      <Route path={routes.notFound} element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
