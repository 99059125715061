import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {useState} from 'react';

import {Dispatch, RootState} from 'environment/store';
import {CHANGE_PASSWORD_SCHEMA} from 'environment/validation';
import {ChangePasswordValues} from 'environment/types/changePassword';
import {ChangePasswordTemplate} from 'templates';
import {routes} from 'environment/constants/routes';

const ChangePassword = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const params = useParams();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {token = ''} = params;

  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.auth,
  );

  const {handleSubmit, control} = useForm<ChangePasswordValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      code: '',
    },
    resolver: CHANGE_PASSWORD_SCHEMA,
  });

  const onSubmit = (value: ChangePasswordValues) => {
    dispatch.auth.changePassword({
      data: {...value, token},
      callback: () => {
        setIsSuccess(true);
        setTimeout(() => {
          navigate(routes.auth.logIn);
        }, 4000);
      },
    });
  };

  return (
    <ChangePasswordTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      isSuccess={isSuccess}
    />
  );
};

export default ChangePassword;
