import {EmployerUrl} from 'environment/types/employer';
import {JobUrl} from 'environment/types/myJobs';
import {SeekerUrl} from 'environment/types/seeker';

export const authUrl = {
  login: '/auth/employer/login',
  signUp: '/auth/employer/init-signup',
  recovery: '/auth/employer/request-change-password',
  changePassword: '/auth/employer/confirm-change-password',
};

export const jobUrl = ({id}: JobUrl) => {
  return {
    create: '/job',
    getJobs: '/employer/jobs',
    getJobDetails: `/job/${id}`,
    deleteJob: `/job/${id}`,
    applicants: `/job/${id}/applicants/`,
  };
};

export const seekerUrl = ({id}: SeekerUrl) => {
  return {
    getExtendedSeeker: `/seeker/${id}/extended`,
  };
};

export const employerUrl = ({id}: EmployerUrl) => {
  return {
    getEmployer: '/employer/whoami',
    employer: `/employer/${id}`,
  };
};
