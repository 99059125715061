import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {TableContainer} from 'atoms';
import {JobDetails} from 'environment/types/myJobs';
import {TableBody, TableHead} from 'molecules';
import {JobDetailsTables} from 'organisms';
import {SeekerData} from 'environment/types/seeker';

interface IProps {
  jobDetails: JobDetails | null;
  jobApplicants: SeekerData[];
  navigateToSeeker: (values: SeekerData) => void;
}

const JobDetailsTemplate = ({
  jobDetails,
  jobApplicants,
  navigateToSeeker,
}: IProps) => {
  const {t} = useTranslation();

  const bodyRowsApplicants = jobApplicants.map(value => ({
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    payRate: value.payRate,
    gender: value.gender,
  }));

  const headCellsApplicants = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'firstName',
      label: t('First.name'),
      sort: false,
    },
    {
      value: 'lastName',
      label: t('Last.name'),
      sort: false,
    },
    {
      value: 'phoneNumber',
      label: t('Phone.number'),
      sort: false,
    },
    {
      value: 'email',
      label: t('Email'),
      sort: false,
    },
    {
      value: 'payRate',
      label: t('Pay.rate'),
      sort: false,
    },
    {
      value: 'gender',
      label: t('Gender'),
      sort: false,
    },
    {
      value: 'info',
      label: t('Info'),
      sort: false,
    },
  ];

  if (!jobDetails) {
    return <></>;
  }

  return (
    <>
      <JobDetailsTables jobDetails={jobDetails} />
      <Typography variant="h5" align="center" sx={{m: '35px 0 20px 0'}}>
        {t('Job.applicants')}
      </Typography>
      {jobApplicants.length ? (
        <TableContainer>
          <TableHead<typeof headCellsApplicants>
            headCells={headCellsApplicants}
          />
          <TableBody
            editBtn
            rows={bodyRowsApplicants}
            editHandler={navigateToSeeker}
          />
        </TableContainer>
      ) : (
        <Typography color={'GrayText'} variant="h4" align="center">
          {t('No.one.applied.for.this.job')}
        </Typography>
      )}
    </>
  );
};

export default JobDetailsTemplate;
