import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {TableContainer} from 'atoms';
import {SeekerData} from 'environment/types/seeker';
import {formatDate} from 'environment/utils/formatDate';
import {TableBody, TableHead, TableVerticalBody} from 'molecules';

interface IProps {
  seekerData: SeekerData;
}

const SeekerDetailsTemplate = ({seekerData}: IProps) => {
  const {t} = useTranslation();

  const showSeekerInfo = {
    id: seekerData.id,
    email: seekerData.email,
    firstName: seekerData.firstName,
    lastName: seekerData.lastName,
    payRate: seekerData.payRate,
    phoneNumber: seekerData.phoneNumber,
    dateOfBirth: formatDate(seekerData.dob, 'day'),
    securityNumber: seekerData.securityNumber,
    gender: seekerData.gender,
    workExperiencesId: seekerData.work_experiences
      .map(value => value.id)
      .join(', '),
    workPreferencesId: seekerData.work_preferences
      .map(value => value.id)
      .join(', '),
    createdAt: formatDate(seekerData.createdAt),
    updatedAt: formatDate(seekerData.updatedAt),
  };

  const headCellsWorkExperiences = [
    {
      value: 'id',
      label: t('Id'),
    },
    {
      value: 'seekerId',
      label: t('Seeker.id'),
    },
    {
      value: 'companyName',
      label: t('Restaurant.chain'),
    },
    {
      value: 'jobType',
      label: t('Job.type'),
    },
    {
      value: 'specializationType',
      label: t('Specialization.type'),
    },
    {
      value: 'mealType',
      label: t('Meal.type'),
    },
    {
      value: 'experienceDuration',
      label: t('Experience.duration'),
    },
    {
      value: 'referenceFullName',
      label: t('Reference.full.name'),
    },
    {
      value: 'referenceEmail',
      label: t('Reference.email'),
    },
    {
      value: 'referencePhoneNumber',
      label: t('Reference.phone.number'),
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
    },
  ];
  const showWorkExperiences = seekerData.work_experiences.map(value => {
    return {
      id: value.id,
      seeker_id: value.seeker_id,
      restaurantChain: value.companyName,
      jobType: value.jobType,
      specializationType: value.specializationType,
      mealType: value.mealType,
      experienceDuration: value.experienceDuration,
      referenceFullName: value.referenceFullName,
      referenceEmail: value.referenceEmail,
      referencePhoneNumber: value.referencePhoneNumber,
      createdAt: formatDate(value.createdAt),
      updatedAt: formatDate(value.updatedAt),
    };
  });

  const headCellsWorkPreferences = [
    {
      value: 'id',
      label: t('Id'),
    },
    {
      value: 'seekerId',
      label: t('Seeker.id'),
    },
    {
      value: 'type',
      label: t('Type'),
    },
    {
      value: 'dayOfWeek',
      label: t('Day.of.week'),
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
    },
  ];
  const showWorkPreferences = seekerData.work_preferences.map(value => {
    return {
      id: value.id,
      seeker_id: value.seeker_id,
      type: value.type,
      dayOfWeek: value.dayOfWeek,
      createdAt: formatDate(value.createdAt),
      updatedAt: formatDate(value.updatedAt),
    };
  });

  return (
    <>
      <Box width={'500px'}>
        <Typography variant="h5" align="center">
          {t('Applicant')}
        </Typography>
        <TableContainer minWidth={400}>
          <TableVerticalBody<typeof showSeekerInfo> rows={showSeekerInfo} />
        </TableContainer>
      </Box>
      <Box mt={7}>
        <Typography variant="h5" align="center">
          {t('Work.experiences')}
        </Typography>
        <TableContainer>
          <TableHead<typeof headCellsWorkExperiences>
            headCells={headCellsWorkExperiences}
          />
          <TableBody rows={showWorkExperiences} />
        </TableContainer>
      </Box>
      <Box mt={7}>
        <Typography variant="h5" align="center">
          {t('Work.preferences')}
        </Typography>
        <TableContainer>
          <TableHead<typeof headCellsWorkPreferences>
            headCells={headCellsWorkPreferences}
          />
          <TableBody rows={showWorkPreferences} />
        </TableContainer>
      </Box>
    </>
  );
};

export default SeekerDetailsTemplate;
