import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {ProfileTemplate} from 'templates';
import {EditValues, EmployerData} from 'environment/types/employer';
import {EDIT_EMPLOYER_SCHEMA} from 'environment/validation';
import {routes} from 'environment/constants/routes';
import {workBridgeEmployer} from 'environment/constants';

const Profile = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const {employer} = useSelector((state: RootState) => state.employer);
  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.employer,
  );

  useEffect(() => {
    dispatch.employer.getEmployer();
  }, [dispatch.employer]);

  const setDefaultValues = (employer: EmployerData) => {
    const [firstName, ...rest] = employer.name.split(' ');

    return {
      id: employer.id,
      email: employer.email || '',
      firstName,
      lastName: rest.join(' '),
      companyName: employer.companyName,
    };
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: {isDirty},
  } = useForm<EditValues>({
    mode: 'onChange',
    defaultValues: setDefaultValues(employer),
    resolver: EDIT_EMPLOYER_SCHEMA,
  });

  useEffect(() => {
    reset(setDefaultValues(employer));
  }, [reset, employer]);

  const onSubmit = (values: EditValues) => {
    if (!isDirty) {
      return;
    }

    const {id, firstName, lastName, companyName, email} = values;
    const data = {
      name: firstName + ' ' + lastName,
      email,
      companyName,
    };
    dispatch.employer.editEmployer({id, body: data});
  };

  const logOut = () => {
    navigate(routes.auth.logIn);
    sessionStorage.removeItem(workBridgeEmployer);
    localStorage.removeItem(workBridgeEmployer);
  };

  const deleteEmployer = () => {
    setOpenConfirmation(true);
  };

  const onConfirm = () => {
    dispatch.employer.deleteEmployer({
      id: employer.id,
      callback: () => {
        logOut();
      },
    });
  };
  const onDismiss = () => {
    setOpenConfirmation(false);
  };

  return (
    <ProfileTemplate
      control={control}
      loading={loading}
      openConfirmation={openConfirmation}
      onSubmit={handleSubmit(onSubmit)}
      logOut={logOut}
      deleteEmployer={deleteEmployer}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  );
};

export default Profile;
