import {workBridgeEmployer} from 'environment/constants';

export const getTokenFromStorage = () => {
  const accessTokenLS = localStorage.getItem(workBridgeEmployer);
  const accessTokenSS = sessionStorage.getItem(workBridgeEmployer);

  return {
    accessTokenLS,
    accessTokenSS,
  };
};
