import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';

import type {RootModel} from '.';
import {getExtendedSeekerRequest} from 'environment/api/services/seeker';
import {SeekerData, SeekerState} from 'environment/types/seeker';

export const seeker = createModel<RootModel>()({
  state: {
    seeker: null,
  } as SeekerState,
  reducers: {
    setSeeker(state, payload: SeekerData) {
      return {
        ...state,
        seeker: payload,
      };
    },
  },
  effects: {
    async getExtendedSeeker(payload) {
      try {
        const {status, data} = await getExtendedSeekerRequest(payload.id);
        if (status === 200) {
          this.setSeeker(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
