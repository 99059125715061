import {Box, Typography} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import {useTranslation} from 'react-i18next';

interface IProps {
  message: string;
}

const SuccessMessage = ({message}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}>
      <Typography variant="h4">{t('Success')}</Typography>
      <VerifiedIcon color="success" sx={{fontSize: 35}} />
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
};

export default SuccessMessage;
