import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {SeekerDetailsTemplate} from 'templates';

const SeekerDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();

  const {seeker} = useSelector((state: RootState) => state.seeker);
  const {jobApplicants} = useSelector((state: RootState) => state.jobs);

  const applicantId = params?.applicantId?.replace(/\D/g, '');
  const jobId = params?.id?.replace(/\D/g, '');

  const applicants = jobApplicants.find(seeker => seeker.id == applicantId);

  useEffect(() => {
    dispatch.seeker.getExtendedSeeker({id: applicantId});
    dispatch.jobs.getJobApplicants({id: jobId});
  }, [dispatch.jobs, dispatch.seeker, jobId, applicantId]);

  if (!seeker) {
    return <></>;
  }

  return (
    <SeekerDetailsTemplate
      seekerData={{...seeker, payRate: applicants?.payRate}}
    />
  );
};

export default SeekerDetails;
