export const routes = {
  dashboard: '/',
  notFound: '*',
  profile: '/profile',
  auth: {
    logIn: '/login',
    singUp: '/signup',
    signUpSuccess: '/signUpSuccess',
    recovery: '/recovery',
    recoveryByToken: '/recovery/:token',
  },
  jobs: {
    list: '/jobs-list',
    createJob: '/create-job',
    listDetailsId: '/jobs-list/:id',
    applicantId: '/jobs-list/:id/:applicantId',
  },
};
