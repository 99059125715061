import moment from 'moment';

export const formatDate = (
  date: string | undefined | null,
  type?: 'day' | 'hour',
) => {
  if (!date) {
    return '';
  }
  if (type === 'day') {
    return moment(date).format('YYYY-MM-DD');
  }
  if (type === 'hour') {
    return moment(date).format('HH:mm');
  }
  return moment(date).format('YYYY-MM-DDTHH:mm');
};
