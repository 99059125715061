import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, Input} from 'atoms';
import {RecoveryValues} from 'environment/types/changePassword';
import {SuccessMessage} from 'molecules';

interface IProps {
  control: Control<RecoveryValues>;
  loading: boolean;
  isSuccess: boolean;
  onSubmit: () => void;
}

const RecoveryTemplate = ({control, loading, isSuccess, onSubmit}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: '0 40px',
      }}>
      <Paper sx={{p: 6}}>
        {isSuccess ? (
          <SuccessMessage
            message={t(
              'Please.follow.the.instructions.in.the.message,we.have.just.sent.you.',
            )}
          />
        ) : (
          <Box component="form" onSubmit={onSubmit} autoComplete="off">
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                mb: '40px',
              }}>
              {t('Password.recovery')}
            </Typography>
            <Input
              control={control}
              name="email"
              label={t('Email')}
              fullWidth
            />
            <Typography
              variant="body2"
              sx={{
                mb: '10px',
              }}>
              {t(
                'Specify.your.e-mail.to.reset.the.password.and.we.will.send.recovery.instructions.',
              )}
            </Typography>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              fullWidth>
              {t('Send')}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default RecoveryTemplate;
