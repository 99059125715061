import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {Dispatch, RootState} from 'environment/store';
import {CreateJobTemplate} from 'templates';
import {CREATE_JOB_SCHEMA} from 'environment/validation';
import {
  CreateJobValues,
  JobShiftType,
  JobType,
} from 'environment/types/createJob';
import {routes} from 'environment/constants/routes';
import {formatDate} from 'environment/utils/formatDate';

const CreateJob = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.jobs,
  );

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: {isDirty},
  } = useForm<CreateJobValues>({
    mode: 'onChange',
    defaultValues: {
      job: {
        title: '',
        description: '',
        jobType: JobType.Production,
        type: JobShiftType.Fulltime,
        phoneNumber: '',
        minExperience: '',
        payRate: undefined,
        companyName: '',
        contactInfo: '',
        startDate: '',
        endDate: null,
        hoursPerWeek: undefined,
      },
      location: {
        state: '',
        city: '',
        street: '',
        number: '',
        zipcode: '',
      },
      schedules: [
        {
          dayOfWeek: 0,
          date: '',
          startTime: '',
          endTime: '',
        },
      ],
    },
    resolver: CREATE_JOB_SCHEMA,
  });

  const onSubmit = (values: CreateJobValues) => {
    if (!isDirty) {
      return;
    }
    const {
      job: {startDate, endDate},
    } = values;

    const newSchedules = values.schedules.map(schedule => ({
      dayOfWeek: schedule.dayOfWeek,
      date: formatDate(schedule.date),
      startTime:
        formatDate(schedule.date, 'day') +
        'T' +
        formatDate(schedule.startTime, 'hour'),
      endTime:
        formatDate(schedule.date, 'day') +
        'T' +
        formatDate(schedule.endTime, 'hour'),
    }));

    const newValues = {
      ...values,
      job: {
        ...values.job,
        startDate: formatDate(startDate),
        endDate: endDate ? formatDate(endDate) : null,
      },
      schedules: newSchedules,
    };

    dispatch.jobs.createJob({
      data: newValues,
      callback: id => {
        navigate(`${routes.jobs.list}/job-${id}`);
      },
    });
  };

  const setValueHandler = (name: any, value: string | number) => {
    setValue(name, value);
    trigger(name);
  };

  return (
    <CreateJobTemplate
      control={control}
      loading={loading}
      setValueHandler={setValueHandler}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default CreateJob;
