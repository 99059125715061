import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button} from 'atoms';
import {EditValues} from 'environment/types/employer';
import {EditProfileForm} from 'organisms';
import {ConfirmDialog} from 'molecules';

interface IProps {
  control: Control<EditValues>;
  loading: boolean;
  openConfirmation: boolean;
  onSubmit: () => void;
  logOut: () => void;
  deleteEmployer: () => void;
  onConfirm: () => void;
  onDismiss: () => void;
}

const ProfileTemplate = ({
  control,
  loading,
  openConfirmation,
  onSubmit,
  logOut,
  deleteEmployer,
  onConfirm,
  onDismiss,
}: IProps) => {
  const {t} = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}>
        <EditProfileForm
          control={control}
          loading={loading}
          onSubmit={onSubmit}
        />
        <Button onClick={logOut} sx={{width: '200px', mt: 6}}>
          {t('Log.out')}
        </Button>
        <Button
          onClick={deleteEmployer}
          color="error"
          sx={{width: '200px', mt: 6}}>
          {t('Delete.employer')}
        </Button>
      </Box>
      <ConfirmDialog
        open={openConfirmation}
        message={t('Do.you.want.to.delete.employer?')}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default ProfileTemplate;
