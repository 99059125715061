import {Models} from '@rematch/core';
import {auth} from './auth';
import {jobs} from './jobs';
import {seeker} from './seeker';
import {employer} from './employer';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  jobs: typeof jobs;
  seeker: typeof seeker;
  employer: typeof employer;
}

export const models: RootModel = {
  auth,
  jobs,
  seeker,
  employer,
};
