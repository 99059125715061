export enum JobType {
  Production = 'production',
  Service = 'service',
  Maintenance = 'maintenance',
}
export enum JobShiftType {
  Fulltime = 'fulltime',
  Parttime = 'parttime',
}

export interface CreateJob {
  title: string;
  description: string;
  jobType: JobType | null;
  type: JobShiftType | null;
  phoneNumber: string;
  minExperience: string;
  payRate: number;
  companyName: string;
  contactInfo: string;
  startDate: string;
  endDate?: string | null;
  hoursPerWeek: number;
}

interface Location {
  street: string;
  number: string;
  city: string;
  state: string;
  zipcode: string;
}
export interface Schedules {
  dayOfWeek: 0;  
  date: string;
  startTime: string;
  endTime: string;
}

export interface CreateJobValues {
  job: CreateJob;
  location: Location;
  schedules: Schedules[]; 
}

export interface CreateJobPayload {
  data: CreateJobValues;
  callback: (id: number) => void;
}
