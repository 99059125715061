import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useNavigate} from 'react-router-dom';

import {SIGN_UP_SCHEMA} from 'environment/validation';
import {Dispatch, RootState} from 'environment/store';
import {SignUpTemplate} from 'templates';
import {routes} from 'environment/constants/routes';
import {SignUpValues} from 'environment/types/signUp';
import {getTokenFromStorage} from 'environment/utils/getTokenFromStorage';

const SignUp = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const {accessTokenLS, accessTokenSS} = getTokenFromStorage();

  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.auth,
  );

  const {handleSubmit, control} = useForm<SignUpValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      name: '',
      companyName: '',
    },
    resolver: SIGN_UP_SCHEMA,
  });

  const onSubmit = (value: SignUpValues) => {
    delete value.passwordConfirm;

    dispatch.auth.signUp({
      data: value,
      callback: () => {
        navigate(routes.auth.signUpSuccess);
      },
    });
  };

  return accessTokenLS || accessTokenSS ? (
    <Navigate to={routes.dashboard} replace={true} />
  ) : (
    <SignUpTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    />
  );
};

export default SignUp;
