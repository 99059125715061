import {useTranslation} from 'react-i18next';

import {ConfirmDialog, TableBody, TableHead} from 'molecules';
import {TableContainer} from 'atoms';
import {JobDetails} from 'environment/types/myJobs';
import {formatDate} from 'environment/utils/formatDate';

interface IProps {
  jobsList: JobDetails[];
  openConfirmation: boolean;
  navigateToDetailsPage: (values: JobDetails) => void;
  deleteJob: (values: JobDetails) => void;
  onConfirm: () => void;
  onDismiss: () => void;
}
const AllMyJobsTemplate = ({
  jobsList,
  openConfirmation,
  navigateToDetailsPage,
  deleteJob,
  onConfirm,
  onDismiss,
}: IProps) => {
  const {t} = useTranslation();
  const headCells = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'title',
      label: t('Title'),
      sort: false,
    },
    {
      value: 'phoneNumber',
      label: t('Phone.number'),
      sort: false,
    },
    {
      value: 'payRate',
      label: t('Pay.rate'),
      sort: false,
    },
    {
      value: 'companyName',
      label: t('Restaurant.chain'),
      sort: false,
    },
    {
      value: 'startDate',
      label: t('Start.date'),
      sort: false,
    },
    {
      value: 'endDate',
      label: t('End.date'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
    {
      value: 'details',
      label: t('Details'),
      sort: false,
    },
    {
      value: 'delete',
      label: t('Delete'),
      sort: false,
    },
  ];

  const bodyRows = jobsList.map(value => ({
    id: value.id,
    title: value.title,
    phoneNumber: value.phoneNumber,
    payRate: value.payRate,
    restaurantChain: value.companyName,
    startDate: formatDate(value.startDate, 'day'),
    endDate: formatDate(value.endDate, 'day'),
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
  }));

  return (
    <>
      <TableContainer>
        <TableHead<typeof headCells> headCells={headCells} />
        <TableBody
          rows={bodyRows}
          editBtn
          editHandler={navigateToDetailsPage}
          deleteBtn
          deleteHandler={deleteJob}
        />
      </TableContainer>
      <ConfirmDialog
        open={openConfirmation}
        message={t('Do.you.want.to.delete.job?')}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default AllMyJobsTemplate;
