import {
  TableSortLabel,
  TableRow,
  TableHead as MUITableHead,
  TableCell,
} from '@mui/material';

import {Order} from 'environment/types';

interface TableHeadProps<T> {
  headCells: any[];
  orderBy?: keyof T;
  order?: Order;
  sortHandler?: (value: keyof T) => void;
}

const TableHead = <T,>({
  headCells,
  orderBy,
  order = 'asc',
  sortHandler = () => {},
}: TableHeadProps<T>) => {
  return (
    <MUITableHead>
      <TableRow>
        {headCells.map(headCell =>
          headCell.sort ? (
            <TableCell key={headCell.value} sx={{fontWeight: 'bold'}}>
              <TableSortLabel
                active={orderBy === headCell.value}
                direction={orderBy === headCell.value ? order : 'asc'}
                onClick={() => sortHandler(headCell.value)}>
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={headCell.value} sx={{fontWeight: 'bold'}}>
              {headCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
