import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, Input} from 'atoms';
import {EditValues} from 'environment/types/employer';

interface IProps {
  control: Control<EditValues>;
  loading: boolean;
  onSubmit: () => void;
}

const EditProfileForm = ({control, loading, onSubmit}: IProps) => {
  const {t} = useTranslation();

  const inputList = [
    {
      name: 'id',
      label: t('Employer.id'),
      disabled: true,
    },
    {
      name: 'email',
      label: t('Email'),
      disabled: false,
    },
    {
      name: 'firstName',
      label: t('First.name'),
      disabled: false,
    },
    {
      name: 'lastName',
      label: t('Last.name'),
      disabled: false,
    },
    {
      name: 'companyName',
      label: t('Restaurant.chain'),
      disabled: false,
    },
  ];

  return (
    <Paper sx={{p: 7}}>
      <Typography textAlign={'center'} mb={3} variant="h4">
        {t('Edit')}
      </Typography>
      <Box
        sx={{display: 'flex', flexDirection: 'column', width: '400px'}}
        component="form"
        onSubmit={onSubmit}
        autoComplete="off">
        {inputList.map(value => (
          <Box key={value.name} sx={{mb: 2, height: '75px'}}>
            <Input
              name={value.name}
              disabled={value.disabled}
              control={control}
              label={value.label}
              fullWidth
            />
          </Box>
        ))}
        <Button fullWidth loading={loading} disabled={loading} type="submit">
          {t('Save')}
        </Button>
      </Box>
    </Paper>
  );
};

export default EditProfileForm;
