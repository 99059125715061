import {SignUpValues} from 'environment/types/signUp';
import axiosService from '../axios';
import {authUrl} from '../url';
import {LogInValues} from 'environment/types/logIn';
import {
  ChangePasswordRequest,
  RecoveryValues,
} from 'environment/types/changePassword';

// LogIn
export const logInRequest = (payload: LogInValues) => {
  return axiosService.post(authUrl.login, payload);
};

// SignUp
export const signUpRequest = (payload: SignUpValues) => {
  return axiosService.post(authUrl.signUp, payload);
};

// Recovery
export const recoveryRequest = (payload: RecoveryValues) => {
  return axiosService.post(authUrl.recovery, payload);
};

// ChangePassword
export const changePasswordRequest = (payload: ChangePasswordRequest) => {
  const {code, email, token, password} = payload;
  return axiosService.post(
    authUrl.changePassword,
    {code, email, password},
    {
      headers: {Authorization: `Bearer ${token}`},
    },
  );
};
