import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

import {Header, SideBar} from 'organisms';

const drawerWidth = 240;
const headerHeight = 70;

interface DashboardTemplateProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const DashboardTemplate = ({
  mobileOpen,
  handleDrawerToggle,
}: DashboardTemplateProps) => {
  return (
    <Box sx={{display: 'flex'}}>
      <Header
        drawerWidth={drawerWidth}
        headerHeight={headerHeight}
        handleDrawerToggle={handleDrawerToggle}
      />
      <SideBar
        drawerWidth={drawerWidth}
        headerHeight={headerHeight}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          minHeight: `calc(100vh - ${headerHeight}px)`,
          height: '100%',
          mt: headerHeight + 'px',
          position: 'relative',
        }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
