import {useState} from 'react';
import {Navigate} from 'react-router-dom';

import {DashboardTemplate} from 'templates';
import {routes} from 'environment/constants/routes';
import { getTokenFromStorage } from 'environment/utils/getTokenFromStorage';

const Dashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const {accessTokenLS, accessTokenSS} = getTokenFromStorage();

  const handleDrawerToggle = () => {
    setMobileOpen(prev => !prev);
  };

  return !accessTokenLS && !accessTokenSS ? (
    <Navigate to={routes.auth.logIn} replace={true} />
  ) : (
    <DashboardTemplate
      handleDrawerToggle={handleDrawerToggle}
      mobileOpen={mobileOpen}
    />
  );
};

export default Dashboard;
