import {Button as MUIButton, CircularProgress} from '@mui/material';

interface ButtonProps {
  loading?: boolean;
  children: string;
  onClick?: () => void;
  [props: string]: any;
}

const Button = ({
  loading = false,
  children,
  onClick = () => {},
  ...props
}: ButtonProps) => {
  const {sx = {}, ...rest} = props;

  return (
    <MUIButton
      onClick={onClick}
      sx={{p: '11px 16px', ...sx}}
      variant="contained"
      {...rest}>
      {loading ? <CircularProgress size={25} /> : children}
    </MUIButton>
  );
};

export default Button;
