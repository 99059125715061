import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {routes} from 'environment/constants/routes';
import {SuccessMessage} from 'molecules';

const VerificationSignUp = () => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: '0 40px',
      }}>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}>
        <SuccessMessage
          message={t(
            'We.send.the.verification.link.and.after.opening.it.from.email.verify.the.account',
          )}
        />
        <Typography component={Link} to={routes.auth.logIn} variant={'subtitle1'}>
          {t('Go.to.Log.in')}
        </Typography>
      </Paper>
    </Box>
  );
};

export default VerificationSignUp;
