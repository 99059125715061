import TextField from '@mui/material/TextField';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker as MUITimePicker} from '@mui/x-date-pickers/TimePicker';
import {Controller, Control} from 'react-hook-form';

interface IProps {
  control: Control<any>;
  name: string;
  [props: string]: any;
}

const TimePicker = ({name, control, ...props}: IProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUITimePicker
              {...field}
              {...props}
              PopperProps={{placement: 'top'}}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  sx={{
                    height: '75px',
                    mb: 1,
                    '& .MuiInputBase-input': {p: '14px', minWidth: '70px'},
                  }}
                  error={Boolean(error)}
                  helperText={error && error.message}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
export default TimePicker;
