import {Box, Typography} from '@mui/material';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {toUpperCaseFirstChar} from 'environment/utils/toUpperCaseFirstChar';
import {Button, Checkbox, Input} from 'atoms';
import {FormFieldsNames, LogInValues} from 'environment/types/logIn';
import {Link} from 'react-router-dom';
import {routes} from 'environment/constants/routes';

const boxShadow = '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
const logInForm: FormFieldsNames[] = ['email', 'password'];

interface LogInTemplateProps {
  control: Control<LogInValues>;
  loading: boolean;
  isRememberMe: boolean;
  onSubmit: () => void;
  handleRememberMe: () => void;
}

const LogInTemplate = ({
  control,
  loading,
  isRememberMe,
  handleRememberMe,
  onSubmit,
}: LogInTemplateProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '500px',
          padding: '50px 20px',
          boxShadow,
          borderRadius: '10px',
        }}>
        <Box mb={3} component="form" onSubmit={onSubmit} autoComplete="off">
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              mb: '20px',
            }}>
            {t('Log.in')}
          </Typography>
          {logInForm.map(fieldName => (
            <Input
              key={fieldName}
              name={fieldName}
              control={control}
              label={toUpperCaseFirstChar(fieldName)}
              showPassword={fieldName === 'password' ? true : false}
              fullWidth
              sx={{mb: 2}}
            />
          ))}
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Checkbox
              label={t('Remember.me')}
              checked={isRememberMe}
              onChange={handleRememberMe}
            />
            <Typography
              component={Link}
              to={routes.auth.recovery}
              color={'red'}
              variant={'subtitle1'}>
              {t('Forgot.password?')}
            </Typography>
          </Box>
          <Button fullWidth loading={loading} disabled={loading} type="submit">
            {t('Log.in')}
          </Button>
        </Box>
        <Typography component="span">{t('I.have.not.account')}</Typography>
        <Box component={Link} ml={1} to={routes.auth.singUp}>
          {t('Sign.up')}
        </Box>
      </Box>
    </Box>
  );
};

export default LogInTemplate;
