import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {JobDetailsTemplate} from 'templates';
import {SeekerData} from 'environment/types/seeker';
import {routes} from 'environment/constants/routes';

const JobDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();
  const navigate = useNavigate();

  const {jobDetails, jobApplicants} = useSelector(
    (state: RootState) => state.jobs,
  );

  const jobId = params?.id?.replace(/\D/g, '');

  useEffect(() => {
    dispatch.jobs.getJobDetails({id: jobId});
    dispatch.jobs.getJobApplicants({id: jobId});
  }, [dispatch, jobId, params.id]);

  const navigateToSeeker = (values: SeekerData) => {
    navigate(`${routes.jobs.list}/job-${jobId}/applicant-${values.id}`);
  };

  return (
    <JobDetailsTemplate
      jobDetails={jobDetails}
      jobApplicants={jobApplicants}
      navigateToSeeker={navigateToSeeker}
    />
  );
};

export default JobDetails;
