import {TextField, TextFieldProps} from '@mui/material';
import {usePlacesWidget} from 'react-google-autocomplete';

interface IProps extends Omit<TextFieldProps, 'placeholder'> {
  onLocationSelect: (locationObj: any) => void;
}

const InputLocation = ({onLocationSelect, ...props}: IProps) => {
  const {sx = {}, ...rest} = props;

  const {ref} = usePlacesWidget({
    apiKey: 'AIzaSyAR6o55yM5DadCH-GlRCYUMrMnlqZkclVI',
    language: 'en',
    options: {
      componentRestrictions: {country: 'us'},
    },
    onPlaceSelected: places => {
      const {address_components} = places;

      if (!!address_components?.length) {
        let locationObj: any = {};

        address_components.forEach((component: any) => {
          const type = component.types[0];
          locationObj[type] = component.long_name;
        });

        onLocationSelect(locationObj);
      }
    },
  });

  return (
    <TextField
      inputRef={ref}
      placeholder=""
      sx={{
        height: '75px',
        mb: 1,
        '& .MuiInputBase-input': {p: '14px'},
        ...sx,
      }}
      {...rest}
    />
  );
};

export default InputLocation;
