import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {
  changePasswordRequest,
  logInRequest,
  recoveryRequest,
  signUpRequest,
} from 'environment/api/services/auth';
import {LogInPayload} from 'environment/types/logIn';
import {SignUpDataType} from 'environment/types/signUp';
import {
  ChangePasswordPayload,
  RecoveryPayload,
} from 'environment/types/changePassword';

export const auth = createModel<RootModel>()({
  state: {
    employer: '',
  },
  reducers: {},
  effects: {
    async logIn(payload: LogInPayload) {
      try {
        const {status, data} = await logInRequest(payload.data);
        if (status === 201) {
          payload.callback(data.id_token);
        }
      } catch (error) {
        const err = error as AxiosError & {statusCode: number};

        if (err.statusCode === 400) {
          toast.error(t('Must.be.a.valid.email'));
          return;
        }

        toast.error(err.message);
      }
    },
    async signUp(payload: SignUpDataType) {
      try {
        const {status} = await signUpRequest(payload.data);
        if (status === 201) {
          payload.callback();
        }
      } catch (error) {
        const err = error as AxiosError & {statusCode: number};

        if (err.statusCode === 400) {
          toast.error(t('Must.be.a.valid.email'));
          return;
        }
        toast.error(err.message);
      }
    },
    async recovery(payload: RecoveryPayload) {
      try {
        const {status} = await recoveryRequest(payload.data);
        if (status === 201) {
          payload.callback();
        }
      } catch (error) {
        const err = error as AxiosError & {statusCode: number};

        if (err.statusCode === 400) {
          toast.error(t('Must.be.a.valid.email'));
          return;
        }

        toast.error(err.message);
      }
    },
    async changePassword(payload: ChangePasswordPayload) {
      try {
        const {status} = await changePasswordRequest(payload.data);
        if (status === 201) {
          payload.callback();
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
