import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker as MUIDatePicker} from '@mui/x-date-pickers/DatePicker';
import {Moment} from 'moment';
import {Controller, Control} from 'react-hook-form';

interface DatePickerProps {
  control: Control<any>;
  name: string;
  handleChange?: (value: Moment) => void;
  [props: string]: any;
}

const DatePicker = ({
  name,
  control,
  handleChange = () => {},
  ...props
}: DatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
              onChange={value => {
                handleChange(value);
                onChange(value);
              }}
              value={value}
              PopperProps={{placement: 'top'}}
              {...props}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  sx={{
                    height: '75px',
                    mb: 1,
                    '& .MuiInputBase-input': {p: '14px', minWidth: '85px'},
                  }}
                  error={Boolean(error)}
                  helperText={error && error.message}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DatePicker;
