import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, Input} from 'atoms';
import {SuccessMessage} from 'molecules';

interface IProps {
  control: Control<any>;
  loading: boolean;
  isSuccess: boolean;
  onSubmit: () => void;
}

const ChangePasswordTemplate = ({
  control,
  loading,
  isSuccess,
  onSubmit,
}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: '0 40px',
      }}>
      <Paper sx={{p: 6, maxWidth: '500px', width: '100%'}}>
        {isSuccess ? (
          <SuccessMessage
            message={t('Your.password.is.successfully.changed!')}
          />
        ) : (
          <Box component="form" onSubmit={onSubmit} autoComplete="off">
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                mb: '50px',
              }}>
              {t('Create.new.password')}
            </Typography>
            <Input
              control={control}
              name="email"
              label={t('Email')}
              fullWidth
              sx={{mb: 2}}
            />
            <Input
              control={control}
              name={'password'}
              label={t('Password')}
              showPassword
              fullWidth
              sx={{mb: 2}}
            />
            <Input
              control={control}
              name={'passwordConfirm'}
              label={t('Confirm.Password')}
              showPassword
              fullWidth
              sx={{mb: 2}}
            />
            <Input
              control={control}
              name={'code'}
              label={t('Code')}
              fullWidth
              sx={{mb: 2}}
            />
            <Button
              fullWidth
              loading={loading}
              disabled={loading}
              type="submit">
              {t('Save')}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ChangePasswordTemplate;
