import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {TableContainer} from 'atoms';
import {JobDetails} from 'environment/types/myJobs';
import {formatDate} from 'environment/utils/formatDate';
import {TableBody, TableHead, TableVerticalBody} from 'molecules';

interface IProps {
  jobDetails: JobDetails;
}

const JobDetailsTables = ({jobDetails}: IProps) => {
  const {t} = useTranslation();

  const showDetailsInfo = {
    id: jobDetails?.id,
    title: jobDetails?.title,
    restaurantChain: jobDetails.companyName,
    description: jobDetails.description,
    contactInfo: jobDetails.contactInfo,
    phoneNumber: jobDetails.phoneNumber,
    hoursPerWeek: jobDetails.hoursPerWeek,
    jobType: jobDetails.jobType,
    minExperience: jobDetails.minExperience,
    payRate: jobDetails.payRate,
    type: jobDetails.type,
    status: jobDetails.status,
    startDate: formatDate(jobDetails.startDate, 'day'),
    endDate: formatDate(jobDetails.endDate, 'day'),
    employer_id: jobDetails.employer_id,
    location_id: jobDetails.location.id,
    workScheduleId: jobDetails.workSchedule.map(value => value.id).join(', '),
    createdAt: formatDate(jobDetails.createdAt),
    updatedAt: formatDate(jobDetails.updatedAt),
  };

  const showLocation = {
    id: jobDetails?.location.id,
    state: jobDetails?.location.state,
    city: jobDetails?.location.city,
    street: jobDetails?.location.street,
    number: jobDetails?.location.number,
    zipCode: jobDetails?.location.zipcode,
    createdAt: formatDate(jobDetails?.createdAt),
    updatedAt: formatDate(jobDetails?.updatedAt),
  };

  const showWorkSchedule = jobDetails.workSchedule.map(value => {
    return {
      id: value.id,
      jobId: value.job_id,
      seekerId: value.seeker_id,
      duration: value.duration,
      dayOfWeek: value.dayOfWeek,
      date: formatDate(value.date, 'day'),
      startTime: value.startTime,
      endTime: value.endTime,
      createdAt: formatDate(value.createdAt),
      updatedAt: formatDate(value.updatedAt),
    };
  });

  const headCellsWorkSchedule = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'jobId',
      label: t('Job.id'),
      sort: false,
    },
    {
      value: 'seekerId',
      label: t('Seeker.id'),
      sort: false,
    },
    {
      value: 'duration',
      label: t('Duration'),
      sort: false,
    },
    {
      value: 'dayOfWeek',
      label: t('Day.of.week'),
      sort: false,
    },
    {
      value: 'date',
      label: t('Date'),
      sort: false,
    },
    {
      value: 'startTime',
      label: t('Start.time'),
      sort: false,
    },
    {
      value: 'endTime',
      label: t('End.time'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
  ];

  return (
    <>
      <Typography variant="h4" align="center">
        {t('Job.details')}
      </Typography>
      <Stack mt={3} direction="row" gap={3} flexWrap="wrap">
        <Box width={'500px'}>
          <Typography variant="h5" align="center">
            {t('Job')}
          </Typography>
          <TableContainer minWidth={400}>
            <TableVerticalBody<typeof showDetailsInfo> rows={showDetailsInfo} />
          </TableContainer>
        </Box>
        <Box width={'500px'}>
          <Typography variant="h5" align="center">
            {t('Location')}
          </Typography>
          <TableContainer minWidth={400}>
            <TableVerticalBody<typeof showLocation> rows={showLocation} />
          </TableContainer>
        </Box>
        <Box sx={{flexGrow: 1}}>
          <Typography variant="h5" align="center">
            {t('Work.schedule')}
          </Typography>
          <TableContainer>
            <TableHead<typeof headCellsWorkSchedule>
              headCells={headCellsWorkSchedule}
            />
            <TableBody rows={showWorkSchedule} />
          </TableContainer>
        </Box>
      </Stack>
    </>
  );
};

export default JobDetailsTables;
