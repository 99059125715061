import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Button} from 'atoms';

interface IProps {
  open: boolean;
  message: string;
  onDismiss: () => void;
  onConfirm: () => void;
}

const ConfirmDialog = ({open, message, onDismiss, onConfirm}: IProps) => {
  const {t} = useTranslation();

  const style = {
    width: '500px',
    height: '220px',
    borderRadius: '10px',
    '& h2': {
      fontSize: '22px',
      letterSpacing: '0.9px',
    },
    '& p': {
      fontSize: '20px',
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
    '& .MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <Dialog sx={{'& .MuiDialog-paper': style}} open={open} onClose={onDismiss}>
      <DialogTitle sx={{fontWeight: '600'}}>{t('Confirmation')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText sx={{color: 'black'}}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{p: '20px', gap: 5}}>
        <Button fullWidth size="large" onClick={onConfirm}>
          {t('Yes')}
        </Button>
        <Button fullWidth size="large" onClick={onDismiss}>
          {t('No')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
