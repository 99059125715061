import {CSSProperties} from 'react';

const Logo = ({style}: {style: CSSProperties}) => {
  return (
    <svg
      width="546"
      height="641"
      viewBox="0 0 546 641"
      fill="none"
      style={style}>
      <path
        d="M0 478.384V339.356C0 333.199 6.66444 329.35 11.9978 332.427L268.997 480.696C271.474 482.124 273 484.766 273 487.625V626.653C273 632.81 266.335 636.66 261.002 633.583L4.00222 485.314C1.52576 483.885 0 481.243 0 478.384Z"
        fill="white"
      />
      <path
        d="M136.5 300.04V160.848C136.5 154.691 143.164 150.842 148.497 153.919L405.497 302.188C407.973 303.617 409.499 306.258 409.499 309.117V551.967C409.499 554.777 408.025 557.381 405.615 558.827L369.115 580.727C363.783 583.926 356.999 580.085 356.999 573.867V445.453C356.999 442.683 355.566 440.109 353.21 438.651L140.289 306.843C137.933 305.384 136.5 302.811 136.5 300.04Z"
        fill="white"
      />
      <path
        d="M262.5 153.043V14.0399C262.5 7.84107 269.246 3.99739 274.579 7.15758L542.078 165.676C544.509 167.116 546 169.732 546 172.558V467.555C546 470.585 544.288 473.355 541.578 474.71L494.578 498.21C489.259 500.87 483 497.002 483 491.055V298.456C483 295.685 481.567 293.112 479.211 291.654L266.29 159.845C263.934 158.387 262.5 155.814 262.5 153.043Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
