import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './environment/plugins/i18n';
import './index.css';
import App from './App';
import {store} from 'environment/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <ToastContainer position="bottom-center" autoClose={3000} />
    </Provider>
  </BrowserRouter>,
);
