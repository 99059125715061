import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {routes} from 'environment/constants/routes';
import {JobDetails} from 'environment/types/myJobs';
import {AllMyJobsTemplate} from 'templates';

const AllMyJobs = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const [openConfirmation, setOpenConfirmation] = useState<string>('');

  const {jobsList} = useSelector((state: RootState) => state.jobs);
  useEffect(() => {
    dispatch.jobs.getJobs();
  }, [dispatch.jobs]);

  const navigateToDetailsPage = (values: JobDetails) => {
    navigate(`${routes.jobs.list}/job-${values.id}`);
  };
  const deleteJob = (values: JobDetails) => {
    setOpenConfirmation(values.id.toString());
  };

  const onConfirm = () => {
    dispatch.jobs.deleteJob({id: Number(openConfirmation)});
    setOpenConfirmation('');
  };
  const onDismiss = () => {
    setOpenConfirmation('');
  };

  return (
    <AllMyJobsTemplate
      jobsList={jobsList}
      openConfirmation={!!openConfirmation}
      navigateToDetailsPage={navigateToDetailsPage}
      deleteJob={deleteJob}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  );
};

export default AllMyJobs;
