import {
  TableRow,
  TableCell,
  TableBody as MUITableBody,
  IconButton,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';

interface TableBodyProps {
  rows: any[];
  editBtn?: boolean;
  deleteBtn?: boolean;
  editHandler?: (values: any) => void;
  deleteHandler?: (values: any) => void;
}

const TableBody = ({
  rows,
  editBtn = false,
  deleteBtn = false,
  editHandler = () => {},
  deleteHandler = () => {},
}: TableBodyProps) => {
  return (
    <MUITableBody>
      {rows.map(row => (
        <TableRow key={row.id}>
          {Object.values(row).map((value, index) => (
            <TableCell key={index}>
              {value === '' || value === null
                ? '-'
                : (value as string | number)}
            </TableCell>
          ))}
          {editBtn && (
            <TableCell>
              <IconButton onClick={() => editHandler(row)}>
                <ModeIcon color="success" />
              </IconButton>
            </TableCell>
          )}
          {deleteBtn && (
            <TableCell>
              <IconButton onClick={() => deleteHandler(row)}>
                <DeleteIcon color="error" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </MUITableBody>
  );
};

export default TableBody;
