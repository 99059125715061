import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {
  createJobRequest,
  deleteJobRequest,
  getJobApplicantsRequest,
  getJobDetailsRequest,
  getJobsRequest,
} from 'environment/api/services/jobs';
import {CreateJobPayload} from 'environment/types/createJob';
import {JobDetails, JobsState} from 'environment/types/myJobs';
import {SeekerData} from 'environment/types/seeker';

export const jobs = createModel<RootModel>()({
  state: {
    jobsList: [],
    jobDetails: null,
    jobApplicants: [],
  } as JobsState,
  reducers: {
    setJobsList(state, payload: JobDetails[]) {
      return {
        ...state,
        jobsList: payload,
      };
    },

    setJobDetails(state, payload: JobDetails) {
      return {
        ...state,
        jobDetails: payload,
      };
    },
    setJobApplicants(state, payload: SeekerData[]) {
      return {
        ...state,
        jobApplicants: payload,
      };
    },
    resetJobDetails(state) {
      return {
        ...state,
        jobDetails: null,
      };
    },
    removeJob(state, payload: {id: number}) {
      return {
        ...state,
        jobsList: state.jobsList.filter(job => job.id !== payload.id),
      };
    },
  },
  effects: {
    async createJob(payload: CreateJobPayload) {
      try {
        const {status, data} = await createJobRequest(payload.data);
        if (status === 201) {
          payload.callback(data.id);
          toast.success(t('Job.has.been.created'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobs() {
      try {
        const {status, data} = await getJobsRequest();
        if (status === 200) {
          this.setJobsList(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobDetails(payload) {
      this.resetJobDetails();
      try {
        const {status, data} = await getJobDetailsRequest(payload.id);
        if (status === 200) {
          this.setJobDetails(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobApplicants(payload) {
      try {
        const {status, data} = await getJobApplicantsRequest(payload.id);
        if (status === 200) {
          this.setJobApplicants(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async deleteJob(payload: {id: number}) {
      const {id} = payload;
      try {
        const {status} = await deleteJobRequest(id);
        if (status === 200) {
          this.removeJob({id});
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
