import {Checkbox as MUICheckbox} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  onChange: () => void;
  [rest: string]: any;
}

const Checkbox = ({checked, label = '', onChange, ...rest}: CheckboxProps) => {
  return (
    <FormControlLabel
      label={label}
      {...rest}
      control={<MUICheckbox checked={checked} onChange={onChange} />}
    />
  );
};

export default Checkbox;
