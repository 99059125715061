import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {CreateJobForm} from 'organisms';
import {CreateJobValues} from 'environment/types/createJob';

interface IProps {
  control: Control<CreateJobValues>;
  loading: boolean;
  setValueHandler: (name: any, value: string | number) => void;
  onSubmit: () => void;
}

const CreateJobTemplate = ({
  control,
  loading,
  onSubmit,
  setValueHandler,
}: IProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <Box p={7} component={Paper}>
        <Typography textAlign={'center'} mb={3} variant="h4">
          {t('Create.a.Job')}
        </Typography>
        <CreateJobForm
          control={control}
          loading={loading}
          onSubmit={onSubmit}
          setValueHandler={setValueHandler}
        />
      </Box>
    </Box>
  );
};

export default CreateJobTemplate;
