import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import i18n from 'i18next';

const password = Yup.string()
  .min(8, `${i18n.t('Minimum.number.of.ranks')} 8`)
  .max(32, `${i18n.t('Maximum.number.of.ranks')} 32`)
  .required(i18n.t('Enter.passswrod'))
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./@ \\#])(?=.{8,})/,
    i18n.t(
      'Must.contain.One.Uppercase.and.One.Number.and.One.Special.Character',
    ),
  )
  .matches(/^(\S+$)/, i18n.t('This.field.must.not.contain.spaces'));

const email = Yup.string()
  .email(`${i18n.t('Must.be.a.valid.email')}`)
  .required(i18n.t('Enter.email'))
  .matches(
    /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    i18n.t('Must.be.a.valid.email'),
  );

const createLocation = Yup.object().shape({
  street: Yup.string().required(`${i18n.t('Enter.street')}`),
  city: Yup.string().required(`${i18n.t('Enter.city')}`),
  state: Yup.string().required(`${i18n.t('Enter.state')}`),
  number: Yup.string()
    .required(`${i18n.t('Enter.number')}`)
    .matches(/^\d+$/, i18n.t('Please.enter.number.value.only'))
    .trim(),
  zipcode: Yup.string()
    .required(`${i18n.t('Enter.ZIP')}`)
    .matches(/^\d+$/, i18n.t('Please.enter.number.value.only'))
    .trim(),
});

const createSchedules = Yup.object().shape({
  dayOfWeek: Yup.number(),
  date: Yup.string().required(`${i18n.t('Enter.date')}`),
  startTime: Yup.string().required(`${i18n.t('Enter.start.time')}`),
  endTime: Yup.string().required(`${i18n.t('Enter.end.time')}`),
});

const createJob = Yup.object().shape({
  title: Yup.string().required(`${i18n.t('Enter.title')}`),
  description: Yup.string().required(`${i18n.t('Enter.description')}`),
  jobType: Yup.string().required(`${i18n.t('Please.select.job.type')}`),
  type: Yup.string().required(`${i18n.t('Please.select.job.shift.type')}`),
  phoneNumber: Yup.string()
    .required(`${i18n.t('Enter.phone.number')}`)
    .matches(/^[+]?\d+$/, i18n.t('Incorrect.phone.number.format')),
  minExperience: Yup.string().required(`${i18n.t('Enter.minimal.experience')}`),
  payRate: Yup.number()
    .typeError(`${i18n.t('Please.enter.number.value.only')}`)
    .required(`${i18n.t('Enter.pay.rate')}`)
    .min(1, `${i18n.t('Enter.pay.rate')}`),
  companyName: Yup.string().required(`${i18n.t('Enter.restaurant.chain')}`),
  contactInfo: Yup.string().required(`${i18n.t('Enter.contact.info')}`),
  startDate: Yup.date()
    .required(`${i18n.t('Enter.start.date')}`)
    .typeError(`${i18n.t('Must.be.a.Date.type')}`),
  endDate: Yup.date()
    .notRequired()
    .nullable()
    .typeError(`${i18n.t('Must.be.a.Date.type')}`)
    .min(
      Yup.ref('startDate'),
      `${i18n.t("End.date.can't.be.before.start.date")}`,
    ),
  hoursPerWeek: Yup.number()
    .typeError(`${i18n.t('Please.enter.number.value.only')}`)
    .required(`${i18n.t('Enter.hours.per.week')}`)
    .min(1, `${i18n.t('Enter.hours.per.week')}`),
});

export const LOGIN_SCHEMA = yupResolver(
  Yup.object().shape({
    email: email,
    password: password,
  }),
);

export const RECOVERY_SCHEMA = yupResolver(
  Yup.object().shape({
    email: email,
  }),
);

export const CHANGE_PASSWORD_SCHEMA = yupResolver(
  Yup.object().shape({
    email: email,
    password: password,
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password')],
      `${i18n.t('Passwords.doesn.t.match')}`,
    ),
    code: Yup.string().required(i18n.t('Enter.code')),
  }),
);

export const SIGN_UP_SCHEMA = yupResolver(
  Yup.object().shape({
    email: email,
    name: Yup.string().required(i18n.t('Enter.name')).trim(),
    companyName: Yup.string().required(i18n.t('Enter.restaurant.chain')).trim(),
    password: password,
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password')],
      `${i18n.t('Passwords.doesn.t.match')}`,
    ),
  }),
);

export const CREATE_JOB_SCHEMA = yupResolver(
  Yup.object().shape({
    job: createJob,
    location: createLocation,
    schedules: Yup.array()
      .of(createSchedules)
      .required(`${i18n.t('Please.add.schedule')}`),
  }),
);

export const EDIT_EMPLOYER_SCHEMA = yupResolver(
  Yup.object().shape({
    id: Yup.string(),
    email: email,
    firstName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.first.name'))
      .trim(),
    lastName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.last.name'))
      .trim(),
    companyName: Yup.string().required(i18n.t('Enter.restaurant.chain')).trim(),
  }),
);
