import axiosService from '../axios';
import {jobUrl} from '../url';
import {CreateJobValues} from 'environment/types/createJob';

export const createJobRequest = (payload: CreateJobValues) => {
  return axiosService.post(jobUrl({}).create, payload);
};

export const getJobsRequest = () => {
  return axiosService.get(jobUrl({}).getJobs);
};

export const getJobDetailsRequest = (id: number) => {
  return axiosService.get(jobUrl({id}).getJobDetails);
};

export const getJobApplicantsRequest = (id: number) => {
  return axiosService.get(jobUrl({id}).applicants);
};

export const deleteJobRequest = (id: number) => {
  return axiosService.delete(jobUrl({id}).deleteJob);
};
