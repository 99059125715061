import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {EmployerData, EmployerState} from 'environment/types/employer';
import {
  deleteEmployerRequest,
  editEmployerRequest,
  getEmployerRequest,
} from 'environment/api/services/employer';

const employerData = {
  id: '',
  email: '',
  name: '',
  companyName: '',
  verified: true,
  createdAt: '',
  updatedAt: '',
};

export const employer = createModel<RootModel>()({
  state: {
    employer: employerData,
  } as EmployerState,
  reducers: {
    setEmployer(state, payload: EmployerData) {
      return {
        ...state,
        employer: payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        employer: employerData,
      };
    },
  },
  effects: {
    async getEmployer() {
      try {
        const {status, data} = await getEmployerRequest();
        if (status === 200) {
          this.setEmployer(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async editEmployer(payload) {
      const {id, body} = payload;
      try {
        const {status, data} = await editEmployerRequest(id, body);
        if (status === 200) {
          this.setEmployer(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async deleteEmployer(payload) {
      try {
        const {status} = await deleteEmployerRequest(payload.id);
        if (status === 200) {
          this.resetState();
          payload.callback();
          toast.success(t('Employer.deleted.successfully'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
