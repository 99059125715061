import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {Controller, Control} from 'react-hook-form';

interface MenuItems {
  value: string;
  label: string;
}

interface SelectorProps {
  control: Control<any>;
  name: string;
  label: string;
  menuItems: MenuItems[];
  fullWidth?: boolean;
}

const Selector = ({
  control,
  name,
  label,
  menuItems,
  fullWidth = false,
}: SelectorProps) => {
  const menuItemsList = menuItems.map(item => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        minWidth: 150,
        height: '75px',
        mb: 1,
        '& .MuiInputBase-input': {p: '14px'},
      }}>
      <InputLabel
        sx={{
          '&.MuiInputLabel-shrink': {
            backgroundColor: 'white',
            pr: '7px',
            pl: '3px',
          },
        }}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        render={({field, fieldState: {error}}) => (
          <>
            <Select {...field}>{menuItemsList}</Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default Selector;
